<template>
    <div class="lvya-businessCenter">
        <div>
            <img src="static/lvya/basicPlatform/businessCenter/lunbo2.png" alt="" style="width:100%;">
        </div>
        <div class="box1-bg">
            <div class="businessCenter-box1">
                <p class="administration-tit">自定义功能引擎</p>
                <p class="businessCenter-box1-text">
                    使用者可根据过程管理系统提供的功能元素快速个性化组装适合自己的应用。
                    在应用使用过程中可以把数据记录和学生的综合能力指标进行关联。
                </p>
                <img src="static/lvya/basicPlatform/businessCenter/zdygnyq.png" alt="" style="width:100%;margin-top:20px;">
            </div>
            <div class="businessCenter-box2">
                <p class="administration-tit">快速生成一站式服务大厅</p>
                <p class="businessCenter-box1-text" style="padding:0 160px;">
                    根据提供的功能元素快速组装适合自己的应用，应用可以发布到Pc端、App、微信、阿里钉钉。
                </p>
                <ul class="yzs-div">
                    <li class="wow animate__zoomIn" data-wow-duration ="1s" data-wow-delay ="0s">
                        <img src="static/lvya/basicPlatform/businessCenter/yzsfw1.png" alt="">
                        <p>一卡通消费</p>
                    </li>
                    <li class="wow animate__zoomIn" data-wow-duration ="1s" data-wow-delay ="0s">
                        <img src="static/lvya/basicPlatform/businessCenter/yzsfw2.png" alt="">
                        <p>新生入学</p>
                    </li>
                    <li class="wow animate__zoomIn" data-wow-duration ="1s" data-wow-delay ="0s">
                        <img src="static/lvya/basicPlatform/businessCenter/yzsfw3.png" alt="">
                        <p>阅读活动</p>
                    </li>
                    <li class="wow animate__zoomIn" data-wow-duration ="1s" data-wow-delay ="0s">
                        <img src="static/lvya/basicPlatform/businessCenter/yzsfw4.png" alt="">
                        <p>迎新管理</p>
                    </li>
                    <li class="wow animate__zoomIn" data-wow-duration ="1s" data-wow-delay ="0s">
                        <img src="static/lvya/basicPlatform/businessCenter/yzsfw5.png" alt="">
                        <p>活动管理</p>
                    </li>
                    <li class="wow animate__zoomIn" data-wow-duration ="1s" data-wow-delay ="0s">
                        <img src="static/lvya/basicPlatform/businessCenter/yzsfw6.png" alt="">
                        <p>日常行为</p>
                    </li>
                     <li class="wow animate__zoomIn" data-wow-duration ="1s" data-wow-delay ="0s">
                        <img src="static/lvya/basicPlatform/businessCenter/yzsfw7.png" alt="">
                        <p>教师日志</p>
                    </li>
                    <li class="wow animate__zoomIn" data-wow-duration ="1s" data-wow-delay ="0s">
                        <img src="static/lvya/basicPlatform/businessCenter/yzsfw8.png" alt="">
                        <p>卫生检查</p>
                    </li>
                    <li class="wow animate__zoomIn" data-wow-duration ="1s" data-wow-delay ="0s">
                        <img src="static/lvya/basicPlatform/businessCenter/yzsfw9.png" alt="">
                        <p>运动会</p>
                    </li>
                    <li class="wow animate__zoomIn" data-wow-duration ="1s" data-wow-delay ="0s">
                        <img src="static/lvya/basicPlatform/businessCenter/yzsfw10.png" alt="">
                        <p>户外活动</p>
                    </li>
                    <li class="wow animate__zoomIn" data-wow-duration ="1s" data-wow-delay ="0s">
                        <img src="static/lvya/basicPlatform/businessCenter/yzsfw11.png" alt="">
                        <p>阅读平台</p>
                    </li>
                    <li class="wow animate__zoomIn" data-wow-duration ="1s" data-wow-delay ="0s">
                        <img src="static/lvya/basicPlatform/businessCenter/yzsfw12.png" alt="">
                        <p>班级考勤</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="box2-bg">
            <div class="businessCenter-box3">
                <p class="administration-tit">伴随式数据采集</p>
                <p class="businessCenter-box1-text">
                    零障碍融合各类应用，组合成大系统。每个小程序均可生成相应的小数据，
                    系统自动生产数据统计图，无需老师手动统计，减轻老师负担，让学校工作更加具有时效性，
                    科学性，及时性；管理方式透明化，简约化，信息化。
                </p>
                <div class="businessCenter-box3-div">
                    <div class="businessCenter-box3-left">
                        <p class="sj-ab">小应用生成小数据</p>
                        <img src="static/lvya/basicPlatform/businessCenter/zdygnyq2.png" alt="" style="width:100%;margin-top:20px;">
                    </div>
                    <div class="businessCenter-box3-right">
                        <p>
                            借助<span>网络化、移动化</span>技术把学校中任何一个教育过程或场景，
                            在<span>手机端</span>及时形成<span>一套应用，</span>让每一个教育场景充分发挥育人功能，
                            伴随式采集学生行为小数据，实现学生<span>过程性评价。</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="businessCenter-box4">
                <p class="green-p">小应用加工处理，转化成学校各类应用系统</p>
                <p style="font-weight: 600;padding: 30px 0;color:#333;">自定义生成各类管理系统</p>
                <el-carousel :interval="3000" type="card">
                  <el-carousel-item v-for="(item,i) in swiperList" :key="i">
                    <img :src="item.imgUrl" alt="" style="width:100%;height:100%;">
                  </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div class="box3-bg">
            <div class="businessCenter-box5">
                <p class="administration-tit">多维度分析学校数据</p>
                <p class="businessCenter-box1-text">
                    帮助学校科学分析管理数据，通过各种可视化的图形或表格进行直观呈现，
                    根据各个场景过程中产生的数据形成学校多方面的画像，直观反映学校现阶段发展状况。
                    基于教学大数据的质量诊断改进，赋予学校修正原有教育过程的能力，提高学校行政管理效率，
                    全面发展校园建设。
                </p>
                <img src="static/lvya/basicPlatform/businessCenter/dwd.png" alt="" style="width:100%;margin-top:20px;">
                <p style="padding-bottom:30px;">帮助每所学校都能快速搭建属于自己的“智慧校园”</p>
            </div>
        </div>
    </div>
</template>
<script>
import { WOW } from 'wowjs'
export default {
    data() {
        return {
          swiperList: [
              {
                  imgUrl: require('../../../../public/static/lvya/basicPlatform/businessCenter/lb1.png'),
              }, {
                  imgUrl: require('../../../../public/static/lvya/basicPlatform/businessCenter/lb2.png'),
              }, {
                  imgUrl: require('../../../../public/static/lvya/basicPlatform/businessCenter/lb4.png'),
              }
          ],
        }
    },
    mounted() {
        this.$nextTick(() => {
            let wow = new WOW({
                boxClass: 'wow',
                animateClass: 'animated',
                offset: '0',
                mobile: true,
                live: true
            })
            wow.init()
        })
    },
}
</script>

<style lang="less" scoped>
.administration-tit{
    text-align: center;
    font-size: 24px;
    color: #333;
    font-weight: 600;
}
.businessCenter-box1{
    width: 1200px;
    margin: 0px auto;
    min-height: 300px;
    padding: 50px 0;
}
.businessCenter-box1-text{
    margin-top: 20px;
    text-align: center;
    line-height: 30px;
    color: #666;
}
.businessCenter-box2{
    width: 1200px;
    min-height: 650px;
    margin: 10px auto;
    padding: 50px 90px;
    background: url(../../../../public/static/lvya/basicPlatform/businessCenter/businessCenterBg2.png) no-repeat;
    background-size: 100% 100%;
}
.box1-bg{
    width: 100%;
    background: url(../../../../public/static/lvya/basicPlatform/businessCenter/businessCenterBg1.png) no-repeat;
    background-size: 100% 100%;
}
.yzs-div{
    width: 100%;
    min-height: 200px;
    background: #fff;
    border-radius: 5px;
    margin: 20px 0 50px 0;
    display: flex;
    padding: 30px 0;
    flex-wrap: wrap;
    -moz-box-shadow:5px 5px 13px #eee; -webkit-box-shadow:5px 5px 13px #eee; box-shadow: 5px 5px 13px #eee;
}
.yzs-div li{
    width: 16%;
    text-align: center;
    margin: 20px 0;
}
.yzs-div li p{
    margin-top: 5px;
}
.businessCenter-box3{
    width: 1200px;
    margin: 0px auto;
    min-height: 300px;
    padding: 50px 0;
}
.businessCenter-box3-div{
    display: flex;
    justify-content: space-between;
}
.businessCenter-box3-left{
    width: 820px;
    position: relative;
}
.businessCenter-box3-right{
    width: 300px;
    height: 320px;
    background: url('../../../../public/static/lvya/basicPlatform/businessCenter/border.png') no-repeat;
    background-size: 100% 100%;
    margin-top: 250px;
}
.businessCenter-box3-right p{
    padding: 55px 40px;
    line-height: 30px;
    text-indent: 35px;
    color: #666;
}
.businessCenter-box3-right p span{
    color: #333;
    font-weight: 600;
}
.businessCenter-box4{
    width: 1200px;
    margin: 0px auto;
    min-height: 300px;
    padding: 50px 0;
    text-align: center;
}
.box3-bg{
    width: 100%;
    background: url('../../../../public/static/lvya/basicPlatform/businessCenter/businessCenterBg4.png') no-repeat;
    background-size: 100% 100%;
}
.businessCenter-box5{
    width: 1200px;
    margin: 0px auto;
    min-height: 300px;
    padding: 50px 0;
    text-align: center;
}
.sj-ab{
    position: absolute;
    right: 140px;
    top: 100px;
    padding: 5px 20px;
    background: #1aca81;
    border-radius: 3px;
    color: #fff;
}
.box2-bg{
    width: 100%;
    background: url('../../../../public/static/lvya/basicPlatform/businessCenter/businessCenterBg3.png') no-repeat;
    background-size: 100% 100%;
}
.green-p{
    padding: 5px 20px;
    background: #1aca81;
    border-radius: 3px;
    color: #fff;
    text-align: center;
    display: inline-block;
}
</style>
